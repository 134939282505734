// Topbar.js

import React from 'react';
import Unit from './Unit';

const Topbar = ({
  unitTypes,
  onDragStartFromTopbar,
  assetsPath,
  unitCategory,
  currentViewMode,
}) => {
  const unitTypeKeys = Object.keys(unitTypes);

  return (
    <div className="topbar z-index-base">
      {unitTypeKeys.map((unitTypeId) => {
        if (
          (currentViewMode === 'basic_edit' && unitTypes[unitTypeId].publicly_configurable === true)
          || (currentViewMode === 'full_edit' && unitTypes[unitTypeId].configurable === true)
        ) {
          return (
            <Unit
              key={unitTypeId}
              unitType={unitTypes[unitTypeId]}
              floorId={`0`}
              rowId={`0`}
              colId={`0`}
              draggable={true}
              onDragStart={onDragStartFromTopbar}
              assetsPath={assetsPath}
              componentSettings={{}}
              unitCategory={unitCategory}
              unitIsRotated={false}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Topbar;
