// Unit.js

import React from 'react';
import { generateComponentsStructure, generateComponentsOpsButtons } from './utils';

const Unit = ({
  unitType,
  floorId,
  rowId,
  colId,
  draggable,
  onDragStart,
  assetsPath,
  componentSettings,
  onEditComponentClick,
  unitStyles,
  unitCategory,
  unitIsRotated,
  currentViewMode,
}) => {
  const unitImageStyles = {};
  const unitComponentsWrapperStyles = {};
  const unitComponentOperationsStyles = {};
  const componentButtons = generateComponentsOpsButtons(unitType.components, unitIsRotated, onEditComponentClick, currentViewMode);
  const unitClasses = [
    'unit',
    (currentViewMode !== 'full_view') && draggable ? 'unit-draggable' : '',
    `id-${unitType.id}`,
    `type-${unitType.type}`,
    unitIsRotated ? 'unit-rotated' : '',
  ];

  return (
    <div
      className={unitClasses.filter(Boolean).join(' ')}
      data-unit-id={unitType.id}
      data-unit-cat={unitCategory}
      data-floor-id={floorId}
      data-row-id={rowId}
      data-col-id={colId}
      {...(currentViewMode !== 'full_view' && {
        draggable: draggable,
        onDragStart: onDragStart,
      })}
    >
      {currentViewMode !== 'full_view' && (
        <div
          className={`unit-components-operations`}
          style={unitComponentOperationsStyles}>
          {componentButtons}
        </div>
      )}
      <div
        className={'unit-components'}
        style={unitComponentsWrapperStyles}>
        {generateComponentsStructure(unitType.components, unitIsRotated, assetsPath, componentSettings, 0)}
      </div>
      <div
        className={`unit-img-wrap`}
        style={unitStyles}>
        <>
          <span className={`unit-id-name`}>{unitType.id}</span>
          <img
            src={assetsPath + unitType.img}
            alt={unitType.id}
            style={unitImageStyles} />
        </>
      </div>
    </div>
  );
};

export default Unit;
