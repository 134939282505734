// variables.js

// The possible view modes of this configurator.
export const appViewModes = {
  full_view: {
    default: true,
  },
  basic_edit: {},
  full_edit: {},
};

// The various kinds of units to which a unitType can belong to.
export const unitTypeCategories= {
  cat_6x3: {
    configurable: true,
    publicly_configurable: true,
  },
  cat_8x3: {
    configurable: true,
    publicly_configurable: false,
  },
  cat_5x2_40: {
    configurable: true,
    publicly_configurable: false,
  },
};

// All types of units with their options
export const unitTypes= {
  cat_6x3: {
    6000: {
      id: '6000',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6000.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6000_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6000_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6100: {
      id: '6100',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6100.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6100_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6100_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6200: {
      id: '6200',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6200.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6200_ext_stairs_1',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6200_ext_door_1',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6200_ext_stairs_2',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6200_ext_door_2',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6200_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6200_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6250: {
      id: '6250',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6250.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6250_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6250_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6250_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6250_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6250_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6250_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6300: {
      id: '6300',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6300.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6300_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6300_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6400: {
      id: '6400',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6400.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6400_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6400_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6500: {
      id: '6500',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6500.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6500_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6500_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6500_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6500_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6550: {
      id: '6550',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6550.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6550_ext_stairs_1',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6550_ext_door_1',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6550_ext_stairs_2',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6550_ext_door_2',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6550_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6550_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6560: {
      id: '6560',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6560.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6560_ext_stairs_1',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6560_ext_door_1',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6560_ext_stairs_2',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6560_ext_door_2',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        }
      ],
      archived: false,
    },
    6600: {
      id: '6600',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6600.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6600_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6600_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6610: {
      id: '6610',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6610.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6610_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6610_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6650: {
      id: '6650',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6650.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6650_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6650_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6650_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6650_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6650_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6650_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6700: {
      id: '6700',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6700.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6700_ext_stairs_1',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6700_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6700_airco_1',
          component_type_id: 'airco_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6700_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {},
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    6800: {
      id: '6800',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6800.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6800_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6800_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6800_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6800_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6800_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6800_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        }
      ],
      archived: false,
    },
    6810: {
      id: '6810',
      type: 'unit_6x3',
      img: 'units/6x3/unit_6810.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_6810_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6810_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6810_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6810_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6810_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_6810_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        }
      ],
      archived: false,
    },
    s6000: {
      id: 's6000',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6000.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6000_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_airco_1',
          component_type_id: 'airco_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6000_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6100: {
      id: 's6100',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6100.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6100_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6100_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6150: {
      id: 's6150',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6150.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6150_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6150_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6155: {
      id: 's6155',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6155.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6155_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_stairs_4',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            top: '-144px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: 'unset',
            top: '55px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_door_4',
          component_type_id: 'ext_door_1_vert',
          styles: {
            top: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_stairs_5',
          component_type_id: 'ext_stairs_2_hor',
          styles: {
            top: '-65px',
            right: '-140px',
          },
          btn_styles: {
            top: '4px',
            right: '62px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_ext_door_5',
          component_type_id: 'ext_door_1_hor',
          styles: {
            top: '-21px',
            right: '13px',
          },
          btn_styles: {
            top: '0',
            right: '13px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6155_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6200: {
      id: 's6200',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6200.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6200_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6200_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6300: {
      id: 's6300',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6300.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6300_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6300_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6350: {
      id: 's6350',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6350.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6350_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6350_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    s6450: {
      id: 's6450',
      type: 'unit_6x3',
      img: 'units/6x3/unit_s6450.svg',
      configurable: true,
      publicly_configurable: true,
      components: [
        {
          id: 'unit_s6450_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '91px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '92px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_s6450_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: 'calc(100% - 55px)',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
  },
  cat_8x3: {
    8000: {
      id: '8000',
      type: 'unit_8x3',
      img: 'units/8x3/unit_8000.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_8000_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_airco_1',
          component_type_id: 'airco_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8000_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    8100: {
      id: '8100',
      type: 'unit_8x3',
      img: 'units/8x3/unit_8100.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_8100_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_stairs_2',
          component_type_id: 'ext_stairs_1_hor',
          styles: {
            bottom: '0',
            left: '12px',
          },
          btn_styles: {
            bottom: '4px',
            left: '64px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_door_2',
          component_type_id: 'ext_door_1_hor',
          styles: {
            bottom: '0',
            left: '13px',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_stairs_3',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_door_3',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_stairs_4',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            top: '25px',
            left: '0',
          },
          btn_styles: {
            top: '154px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_ext_door_4',
          component_type_id: 'ext_door_1_vert',
          styles: {
            top: '178px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_airco_1',
          component_type_id: 'airco_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8100_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    8150: {
      id: '8150',
      type: 'unit_8x3',
      img: 'units/8x3/unit_8150.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_8150_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            top: '25px',
            left: '0',
          },
          btn_styles: {
            top: '154px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8150_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            top: '178px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8150_airco_1',
          component_type_id: 'airco_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8150_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    8200: {
      id: '8200',
      type: 'unit_8x3',
      img: 'units/8x3/unit_8200.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_8200_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {
            bottom: '60px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_ext_stairs_2',
          component_type_id: 'ext_stairs_2_vert',
          styles: {
            bottom: '7px',
            left: 'calc(100% + 65px)',
          },
          btn_styles: {
            bottom: '60px',
            left: 'calc(100% - 24px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_ext_door_2',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '8px',
            left: 'calc(100% - 5px)',
          },
          btn_styles: {
            marginLeft: '-19px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_ext_stairs_3',
          component_type_id: 'ext_stairs_2_hor',
          styles: {
            top: '-65px',
            left: '7px',
          },
          btn_styles: {
            top: '4px',
            left: '55px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_ext_door_3',
          component_type_id: 'ext_door_1_hor',
          styles: {
            top: '-21px',
            left: '7px',
          },
          btn_styles: {
            top: '0',
            left: '7px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_airco_1',
          component_type_id: 'airco_1',
          styles: {
            top: '100px',
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          btn_styles: {
            top: '100px',
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8200_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            top: '100px',
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            top: '100px',
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    8300: {
      id: '8300',
      type: 'unit_8x3',
      img: 'units/8x3/unit_8300.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_8300_ext_stairs_1',
          component_type_id: 'ext_stairs_1_vert',
          styles: {
            top: '25px',
            left: '0',
          },
          btn_styles: {
            top: '154px',
            left: '4px',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8300_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            top: '178px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8300_airco_1',
          component_type_id: 'airco_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) - 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_8300_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-8x3-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
  },
  cat_5x2_40: {
    5000: {
      id: '5000',
      type: 'unit_5x2_40',
      img: 'units/5x2_40/unit_5000.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_5000_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            top: '7px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_5000_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-5x2_40-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-5x2_40-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
    5100: {
      id: '5100',
      type: 'unit_5x2_40',
      img: 'units/5x2_40/unit_5100.svg',
      configurable: true,
      publicly_configurable: false,
      components: [
        {
          id: 'unit_5100_ext_door_1',
          component_type_id: 'ext_door_1_vert',
          styles: {
            bottom: '7px',
            left: '0',
          },
          btn_styles: {},
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
        {
          id: 'unit_5100_window_bars_1',
          component_type_id: 'window_bars_1',
          styles: {
            left: 'calc((var(--floor-grid-5x2_40-cell-width) / 2) + 18px)',
          },
          btn_styles: {
            left: 'calc((var(--floor-grid-5x2_40-cell-width) / 2) + 18px)',
          },
          configurable: true,
          publicly_configurable: false,
          components: [],
        },
      ],
      archived: false,
    },
  }
};

export const componentTypes = {
  ext_door_1_vert: {
    id: 'ext_door_1_vert',
    type: 'door_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_door_1/exterior-door-1-0.svg',
      1: 'components/exterior_door_1/exterior-door-1-1.svg',
      2: 'components/exterior_door_1/exterior-door-1-2.svg',
      3: 'components/exterior_door_1/exterior-door-1-3.svg',
      4: 'components/exterior_door_1/exterior-door-1-4.svg',
      5: 'components/exterior_door_1/exterior-door-1-5.svg',
    },
    component_styles: {
      width: '45px',
      height: '45px',
      marginLeft: '-20.5px',
    },
    image_styles: {
    },
    edit_btn_styles: {
      width: '24px',
      height: '45px',
    },
  },
  ext_door_1_hor: {
    id: 'ext_door_1_hor',
    type: 'door_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_door_1/exterior-door-1-0.svg',
      1: 'components/exterior_door_1/exterior-door-1-1.svg',
      2: 'components/exterior_door_1/exterior-door-1-2.svg',
      3: 'components/exterior_door_1/exterior-door-1-3.svg',
      4: 'components/exterior_door_1/exterior-door-1-4.svg',
      5: 'components/exterior_door_1/exterior-door-1-5.svg',
    },
    component_styles: {
      width: '45px',
      height: '45px',
      marginBottom: '-20.5px',
    },
    image_styles: {
      transform: 'rotate(90deg)',
    },
    edit_btn_styles: {
      width: '45px',
      height: '24px',
    },
  },
  airco_1: {
    id: 'airco_1',
    type: 'airco',
    default_img: '0',
    component_images: {
      0: 'components/airco/airco-0.svg',
      1: 'components/airco/airco-1.svg',
      2: 'components/airco/airco-2.svg',
    },
    component_styles: {
      top: '10px',
      left: 'calc((var(--floor-grid-6x3-cell-width) / 2) - 18px)',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
    },
    image_styles: {
    },
    edit_btn_styles: {
      top: '10px',
      left: 'calc((var(--floor-grid-6x3-cell-width) / 2) - 18px)',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
    },
  },
  window_bars_1: {
    id: 'window_bars_1',
    type: 'window_bars',
    default_img: '0',
    component_images: {
      0: 'components/window_bars/bars-0.svg',
      1: 'components/window_bars/bars-1.svg',
    },
    component_styles: {
      top: '10px',
      left: 'calc((var(--floor-grid-6x3-cell-width) / 2) + 18px)',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
    },
    image_styles: {
    },
    edit_btn_styles: {
      top: '10px',
      left: 'calc((var(--floor-grid-6x3-cell-width) / 2) + 18px)',
      width: '24px',
      height: '24px',
      marginLeft: '-12px',
    },
  },
  ext_stairs_1_vert: {
    id: 'ext_stairs_1_vert',
    type: 'stairs_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_stairs_1/exterior-stairs-vert-1-0.svg',
      1: 'components/exterior_stairs_1/exterior-stairs-vert-1-1.svg',
      2: 'components/exterior_stairs_1/exterior-stairs-vert-1-2.svg',
    },
    component_styles: {
      width: '65px',
      height: '350px',
      marginLeft: '-65px',
      marginBottom: '-152px',
    },
    image_styles: {
    },
    edit_btn_styles: {
      width: '20px',
      height: '20px',
    },
  },
  ext_stairs_2_vert: {
    id: 'ext_stairs_2_vert',
    type: 'stairs_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_stairs_1/exterior-stairs-vert-1-0.svg',
      1: 'components/exterior_stairs_1/exterior-stairs-vert-1-1.svg',
      2: 'components/exterior_stairs_1/exterior-stairs-vert-1-2.svg',
    },
    component_styles: {
      width: '65px',
      height: '350px',
      marginLeft: '-65px',
      marginBottom: '-152px',
    },
    image_styles: {
      transform: 'rotate(180deg)',
    },
    edit_btn_styles: {
      width: '20px',
      height: '20px',
    },
  },
  ext_stairs_1_hor: {
    id: 'ext_stairs_1_hor',
    type: 'stairs_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_stairs_1/exterior-stairs-hor-1-0.svg',
      1: 'components/exterior_stairs_1/exterior-stairs-hor-1-1.svg',
      2: 'components/exterior_stairs_1/exterior-stairs-hor-1-2.svg',
    },
    component_styles: {
      width: '350px',
      height: '65px',
      marginBottom: '-65px',
      marginLeft: '-152px',
    },
    image_styles: {
    },
    edit_btn_styles: {
      width: '20px',
      height: '20px',
    },
  },
  ext_stairs_2_hor: {
    id: 'ext_stairs_2_hor',
    type: 'stairs_exterior',
    default_img: '0',
    component_images: {
      0: 'components/exterior_stairs_1/exterior-stairs-hor-1-0.svg',
      1: 'components/exterior_stairs_1/exterior-stairs-hor-1-1.svg',
      2: 'components/exterior_stairs_1/exterior-stairs-hor-1-2.svg',
    },
    component_styles: {
      width: '350px',
      height: '65px',
      marginBottom: '-65px',
      marginLeft: '-152px',
    },
    image_styles: {
      transform: 'rotate(180deg)',
    },
    edit_btn_styles: {
      width: '20px',
      height: '20px',
    },
  },
};

// The default number of floors and their title
export const floorTitles = [
  { id: '0', translation_id: 'ground_floor_title', translation_default: 'Ground floor' },
  { id: '1', translation_id: 'first_floor_title', translation_default: 'First floor' },
  { id: '2', translation_id: 'second_floor_title', translation_default: 'Second floor' }
];

export const floorPlanDefaults = {
  cat_6x3: {
    defaultFloors: 1,
    minFloors: 1,
    maxFloors: 3,
    defaultFloorRows: 1,
    minFloorRows: 1,
    maxFloorRows: 6,
    defaultFloorCols: 7,
    minFloorCols: 1,
    maxFloorCols: 20,
  },
  cat_8x3: {
    defaultFloors: 1,
    minFloors: 1,
    maxFloors: 3,
    defaultFloorRows: 1,
    minFloorRows: 1,
    maxFloorRows: 6,
    defaultFloorCols: 7,
    minFloorCols: 1,
    maxFloorCols: 20,
  },
  cat_5x2_40: {
    defaultFloors: 1,
    minFloors: 1,
    maxFloors: 1,
    defaultFloorRows: 1,
    minFloorRows: 1,
    maxFloorRows: 6,
    defaultFloorCols: 7,
    minFloorCols: 1,
    maxFloorCols: 20,
  }
};

export const translations = {
  add_floor_btn_lbl: {
    en: 'Floor +',
    nl: 'Verdiep +',
    fr: 'Etage +',
  },
  remove_floor_btn_lbl: {
    en: 'Floor -',
    nl: 'Verdiep -',
    fr: 'Etage -',
  },
  add_row_btn_lbl: {
    en: 'Row +',
    nl: 'Rij +',
    fr: 'Règle +',
  },
  remove_row_btn_lbl: {
    en: 'Row -',
    nl: 'Rij -',
    fr: 'Règle -',
  },
  add_col_btn_lbl: {
    en: 'Column +',
    nl: 'Kolom +',
    fr: 'Colonne +',
  },
  remove_col_btn_lbl: {
    en: 'Column -',
    nl: 'Kolom -',
    fr: 'Colonne -',
  },
  ground_floor_title: {
    en: 'Ground floor',
    nl: 'Gelijkvloers',
    fr: 'Rez-de-chaussée',
  },
  first_floor_title: {
    en: 'First floor',
    nl: 'Eerste verdiep',
    fr: 'Premier étage',
  },
  second_floor_title: {
    en: 'Second floor',
    nl: 'Tweede verdiep',
    fr: 'Second étage',
  },
  cat_6x3: {
    en: 'Units of 6 by 3',
    nl: 'Units van 6 op 3',
    fr: 'Unités de 6 sur 3',
  },
  cat_8x3: {
    en: 'Units of 8 by 3',
    nl: 'Units van 8 op 3',
    fr: 'Unités de 8 sur 3',
  },
  cat_5x2_40: {
    en: 'Units of 5 by 2.40',
    nl: 'Units van 5 op 2.40',
    fr: 'Unités de 5 sur 2.40',
  },
  config_title_6x3: {
    en: 'Configuration for 6 by 3 units',
    nl: 'Configuratie voor units van 6 op 3',
    fr: 'Configuration pour les Unités de 6 sur 3',
  },
  config_title_8x3: {
    en: 'Configuration for 6 by 3 units',
    nl: 'Configuratie voor units van 8 op 3',
    fr: 'Configuration pour les Unités de 8 sur 3',
  },
  config_title_5x2_40: {
    en: 'Configuration for 5 by 2.40 units',
    nl: 'Configuratie voor units van 5 op 2.40',
    fr: 'Configuration pour les Unités de 5 sur 2.40',
  },
  legend_title: {
    en: 'Legend',
    nl: 'Legenda',
    fr: 'Légende',
  },
  legend_win_bars: {
    en: 'Window bars',
    nl: 'Tralies voor ramen',
    fr: 'Barreaux de fenêtre',
  },
  legend_airco: {
    en: 'Airconditioning',
    nl: 'Airco',
    fr: 'Climatisation',
  },
  legend_heat: {
    en: 'Heat pump',
    nl: 'Warmtepomp',
    fr: 'Pompe à chaleur',
  },
  legend_boiler: {
    en: 'Boiler',
    nl: 'Boiler',
    fr: 'Chaudière',
  },
  legend_electrical_cabinet: {
    en: 'Electrical cabinet',
    nl: 'Elektriciteitskast',
    fr: 'Boîtier électrique',
  },
  legend_light: {
    en: 'Light bulb',
    nl: 'Lamp',
    fr: 'Lampe',
  },
  legend_power_outlet: {
    en: 'Power outlet',
    nl: 'Stopcontact',
    fr: 'Prise de courant',
  },
  legend_heating: {
    en: 'Heating',
    nl: 'Verwarming',
    fr: 'Chauffage',
  },
};
