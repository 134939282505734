// utils.js

import {
  unitTypeCategories,
  unitTypes,
  componentTypes,
  translations
} from './variables';
import React from 'react';

export const getComponentTypeProperty = (id, property, componentTypes) => {
  const component_type = componentTypes[id] || {};

  if (component_type[property]) {
    return component_type[property];
  } else {
    return null;
  }
};

export const updateFloorContent = (data, operation = 'add-unit', floorContent, setFloorContent, unitCategory) => {
  setFloorContent(prevFloorContent => {
    const newFloorContent = { ...prevFloorContent };
    let executeOperation = true;
    let unit = data;
    let floorId = 0;
    let rowId = 0;
    let colId = 0;
    let unitId = '';

    if (operation === 'update-component') {
      if (('unit' in data) && (data.unit instanceof HTMLElement)) {
        unit = data.unit;
      } else {
        executeOperation = false;
      }
    }

    if (operation === 'move-unit') {
      if (
        ('unitId' in data) && (data.unitId !== null)
        && ('source' in data)
        && ('destination' in data)
        && ('floorId' in data.source) && (!isNaN(data.source.floorId))
        && ('rowId' in data.source) && (!isNaN(data.source.rowId))
        && ('colId' in data.source) && (!isNaN(data.source.colId))
        && ('floorId' in data.destination) && (!isNaN(data.destination.floorId))
        && ('rowId' in data.destination) && (!isNaN(data.destination.rowId))
        && ('colId' in data.destination) && (!isNaN(data.destination.colId))
      ) {
        floorId = data.destination.floorId;
        rowId = data.destination.rowId;
        colId = data.destination.colId;
        unitId = data.unitId;
      } else {
        executeOperation = false;
      }
    }

    if (operation === 'update-rotation') {
      if (
        ('unitId' in data) && (data.unitId !== null)
        && ('rotation' in data)
        && ('floorId' in data) && (!isNaN(data.floorId))
        && ('rowId' in data) && (!isNaN(data.rowId))
        && ('colId' in data) && (!isNaN(data.colId))
      ) {
        floorId = data.floorId;
        rowId = data.rowId;
        colId = data.colId;
        unitId = data.unitId;
      } else {
        executeOperation = false;
      }
    }

    // Prepare unit Data, data is a unit div.
    if ((operation === 'add-unit')
      || (operation === 'delete-unit')
      || (operation === 'update-component')
    ) {
      if (executeOperation
        && (unit instanceof HTMLElement)
      ) {
        if (isDataAttributeValid(unit, 'data-floor-id')) {
          floorId = parseInt(unit.dataset.floorId);
        } else {
          executeOperation = false;
        }

        if (isDataAttributeValid(unit, 'data-row-id')) {
          rowId = parseInt(unit.dataset.rowId);
        } else {
          executeOperation = false;
        }

        if (isDataAttributeValid(unit, 'data-col-id')) {
          colId = parseInt(unit.dataset.colId);
        } else {
          executeOperation = false;
        }

        if (isDataAttributeValid(unit, 'data-unit-id')) {
          unitId = unit.dataset.unitId;
        } else {
          executeOperation = false;
        }
      } else {
        executeOperation = false;
      }
    }

    if (
      (operation === 'remove-floor')
      || (operation === 'remove-row')
      || (operation === 'remove-col')
    ) {
      if (
        !('deletedId' in data)
        || !('newValueForLevel' in data)
      ) {
        executeOperation = false;
      }
    }

    if (
      unitCategory === null
      || !Object.keys(unitTypeCategories).includes(unitCategory)
    ) {
      executeOperation = false;
    }

    switch(operation) {
      case 'add-unit':
        if (executeOperation) {
          buildUpFloorContentUnits(newFloorContent, unitCategory, floorId, rowId, colId, unitId, { components: {} });
        }
        break;

      case 'move-unit':
        if (
          executeOperation
          && (prevFloorContent[unitCategory].units !== undefined)
          && (data.source.floorId in prevFloorContent[unitCategory].units)
          && (data.source.rowId in prevFloorContent[unitCategory].units[data.source.floorId])
          && (data.source.colId in prevFloorContent[unitCategory].units[data.source.floorId][data.source.rowId])
          && (data.unitId in prevFloorContent[unitCategory].units[data.source.floorId][data.source.rowId][data.source.colId])
        ) {
          const existingUnitProperties = prevFloorContent[unitCategory].units[data.source.floorId][data.source.rowId][data.source.colId][data.unitId];
          // Create entry with new data.
          buildUpFloorContentUnits(newFloorContent, unitCategory, floorId, rowId, colId, unitId, existingUnitProperties);
          // Cleanup old entry.
          cleanUpFloorContentUnits(newFloorContent, unitCategory, data.source.floorId, data.source.rowId, data.source.colId, data.unitId);
        }
        break;

      case 'delete-unit':
        if (
          executeOperation
          && newFloorContent[unitCategory].units[floorId]
          && newFloorContent[unitCategory].units[floorId][rowId]
          && newFloorContent[unitCategory].units[floorId][rowId][colId]
        ) {
          cleanUpFloorContentUnits(newFloorContent, unitCategory, floorId, rowId, colId, unitId);
        }
        break;

      case 'add-floor':
        if (!isNaN(data)) {
          newFloorContent[unitCategory].floors = data;
        }
        break;

      case 'remove-floor':
        if (!isNaN(data.deletedId)) {
          const rootKeys = Object.keys(newFloorContent);

          for (const rootKey of rootKeys) {
            if (data.deletedId in newFloorContent[rootKey].units) {
              delete newFloorContent[rootKey].units[data.deletedId];
            }
          }
        }

        if (!isNaN(data.newValueForLevel)) {
          newFloorContent[unitCategory].floors = data.newValueForLevel;
        }
        break;

      case 'add-row':
        if (!isNaN(data)) {
          newFloorContent[unitCategory].rows = data;
        }
        break;

      case 'remove-row':
        if (!isNaN(data.deletedId)) {
          const rootKeys = Object.keys(newFloorContent);

          for (const rootKey of rootKeys) {
            const floorKeys = Object.keys(newFloorContent[rootKey].units);

            for (const floorKey of floorKeys) {
              if (data.deletedId in newFloorContent[rootKey].units[floorKey]) {
                delete newFloorContent[rootKey].units[floorKey][data.deletedId];
              }
            }
          }
        }

        if (!isNaN(data.newValueForLevel)) {
          newFloorContent[unitCategory].rows = data.newValueForLevel;
        }
        break;

      case 'add-col':
        if (!isNaN(data)) {
          newFloorContent[unitCategory].cols = data;
        }
        break;

      case 'remove-col':
        if (!isNaN(data.deletedId)) {
          const rootKeys = Object.keys(newFloorContent);

          for (const rootKey of rootKeys) {
            const floorKeys = Object.keys(newFloorContent[rootKey].units);

            for (const floorKey of floorKeys) {
              const rowKeys = Object.keys(newFloorContent[rootKey].units[floorKey]);

              for (const rowKey of rowKeys) {
                if (data.deletedId in newFloorContent[rootKey].units[floorKey][rowKey]) {
                  delete newFloorContent[rootKey].units[floorKey][rowKey][data.deletedId];
                }
              }
            }
          }
        }

        if (!isNaN(data.newValueForLevel)) {
          newFloorContent[unitCategory].cols = data.newValueForLevel;
        }
        break;

      case 'update-component':
        if (newFloorContent[unitCategory].units[floorId][rowId][colId][unitId]) {
          if (data.component_value !== parseInt(data.component_default)) {
            newFloorContent[unitCategory].units[floorId][rowId][colId][unitId].components[data.component_id] = data.component_value;
          } else {
            if (newFloorContent[unitCategory].units[floorId][rowId][colId][unitId].components[data.component_id]) {
              delete newFloorContent[unitCategory].units[floorId][rowId][colId][unitId].components[data.component_id];
            }
          }
        }
        break;

      case 'update-rotation':
        if (
          executeOperation
          && (prevFloorContent[unitCategory].units !== undefined)
          && (data.floorId in prevFloorContent[unitCategory].units)
          && (data.rowId in prevFloorContent[unitCategory].units[data.floorId])
          && (data.colId in prevFloorContent[unitCategory].units[data.floorId][data.rowId])
          && (data.unitId in prevFloorContent[unitCategory].units[data.floorId][data.rowId][data.colId])
        ) {
          newFloorContent[unitCategory].units[floorId][rowId][colId][unitId].rotation = data.rotation;
        }
        break;
      default:
        // No idea what should be done by default...
        break;
    }

    return newFloorContent;
  });
};

export const buildUpFloorContentUnits = (newFloorContent, unitCategory, floorId, rowId, colId, unitId, unitProperties) => {
  if (!newFloorContent[unitCategory].units[floorId]) {
    newFloorContent[unitCategory].units[floorId] = {};
  }

  if (!newFloorContent[unitCategory].units[floorId][rowId]) {
    newFloorContent[unitCategory].units[floorId][rowId] = {};
  }

  if (!newFloorContent[unitCategory].units[floorId][rowId][colId]) {
    newFloorContent[unitCategory].units[floorId][rowId][colId] = {};
  }

  if (!newFloorContent[unitCategory].units[floorId][rowId][colId][unitId]) {
    newFloorContent[unitCategory].units[floorId][rowId][colId][unitId] = {};
  }

  newFloorContent[unitCategory].units[floorId][rowId][colId][unitId] = unitProperties;
};

export const cleanUpFloorContentUnits = (newFloorContent, unitCategory, floorId, rowId, colId, unitId) => {
  // Check if the unitId exists before deletion
  if (newFloorContent[unitCategory].units[floorId][rowId][colId][unitId]) {
    delete newFloorContent[unitCategory].units[floorId][rowId][colId][unitId];
  }

  // Check if colId is empty, and unset it if it is
  if (Object.keys(newFloorContent[unitCategory].units[floorId][rowId][colId]).length === 0) {
    delete newFloorContent[unitCategory].units[floorId][rowId][colId];
  }

  // Check if rowId is empty, and unset colId if it is
  if (Object.keys(newFloorContent[unitCategory].units[floorId][rowId]).length === 0) {
    delete newFloorContent[unitCategory].units[floorId][rowId];
  }

  // Check if floorId is empty, and unset rowId if it is
  if (Object.keys(newFloorContent[unitCategory].units[floorId]).length === 0) {
    delete newFloorContent[unitCategory].units[floorId];
  }
};

export const isDataAttributeValid = (element, attributeName) => {
  if (element.hasAttribute(attributeName)) {
    const attributeValue = element.getAttribute(attributeName);
    let trimmedAttributeValue = attributeValue.trim();

    return trimmedAttributeValue !== "";
  } else {
    // The attribute does not exist
    return false;
  }
};

export const generateOppositeStyles = (styles) => {
  const cssOppositesMap = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
    marginTop: 'marginBottom',
    marginBottom: 'marginTop',
    marginLeft: 'marginRight',
    marginRight: 'marginLeft',
    paddingTop: 'paddingBottom',
    paddingBottom: 'paddingTop',
    paddingLeft: 'paddingRight',
    paddingRight: 'paddingLeft',
  };

  return Object.keys(styles).reduce((oppositeStyles, originalProperty) => {
    const value = styles[originalProperty];
    const oppositeProperty = cssOppositesMap[originalProperty];

    if (oppositeProperty) {
      oppositeStyles[oppositeProperty] = value;
    } else {
      oppositeStyles[originalProperty] = value;
    }

    return oppositeStyles;
  }, {});
};

export const generateComponentsStructure = (components, unitIsRotated, assetsPath, componentSettings, parentLvl) => {
  const currentLvl = parentLvl++;

  if (!components || components.length === 0) {
    return null;
  }

  return (
    <>{
      components.map((component, componentIndex) => {
        const componentTypeDefaultImage = parseInt(getComponentTypeProperty(component.component_type_id, 'default_img', componentTypes));

        // Define the selected image by a default or the value in componentSettings if the key exists.
        let componentSelectedImage = componentTypeDefaultImage;
        if (componentSettings[component.id]) {
          componentSelectedImage = componentSettings[component.id];
        }

        const componentTypeImage = componentTypes[component.component_type_id].component_images[componentSelectedImage];
        const shouldRenderImage = !isNaN(componentTypeDefaultImage) && componentTypeImage !== null && componentTypeImage !== undefined;

        const componentCustomStyles = component.styles || {};
        const componentTypeStyles = getComponentTypeProperty(component.component_type_id, 'component_styles', componentTypes) || {};

        const componentCustomImageStyles = component.image_styles || {};
        const componentTypeImageStyles = getComponentTypeProperty(component.component_type_id, 'image_styles', componentTypes);

        let componentStyles = { ...componentTypeStyles, ...componentCustomStyles };
        let imageStyles = { ...componentTypeImageStyles, ...componentCustomImageStyles };

        if (unitIsRotated) {
          const originalComponentStyles = componentStyles;
          componentStyles = generateOppositeStyles(originalComponentStyles);
          componentStyles.transform = 'rotate(180deg)';
        }

        return (
          <div
            key={component.id + '_' + componentIndex}
            className={`component component-lvl${currentLvl} ${component.id} component-${getComponentTypeProperty(component.component_type_id, 'type', componentTypes)}`}
            data-component-id={component.id}
            style={componentStyles}>
            {generateComponentsStructure(component.components, unitIsRotated, assetsPath, componentSettings, currentLvl)}
            {shouldRenderImage && (
              <img
                src={assetsPath + componentTypeImage}
                data-component-image-id={`0`}
                alt={component.id}
                style={imageStyles} />
            )}
          </div>
        );
      })
    }</>
  );
};

export const getComponentsInAllLevels = (components, currentViewMode, buttons = [], parentLvl) => {
  const currentLvl = parentLvl++;

  components.forEach((component, componentIndex) => {
    const componentType = getComponentTypeProperty(component.component_type_id, 'type', componentTypes);
    const componentCustomStyles = component.styles || {};
    const componentCustomBtnStyles = component.btn_styles || {};
    const componentTypeStyles = getComponentTypeProperty(component.component_type_id, 'component_styles', componentTypes) || {};
    const componentTypeBtnStyles = getComponentTypeProperty(component.component_type_id, 'edit_btn_styles', componentTypes) || {};
    let componentStyles = { ...componentTypeStyles, ...componentCustomStyles };
    let componentBtnStyles = { ...componentTypeBtnStyles, ...componentCustomStyles, ...componentCustomBtnStyles };

    if (
      (
        (('configurable' in component) && (currentViewMode === 'full_edit') && (component.configurable === true))
        || (('publicly_configurable' in component) && (currentViewMode === 'basic_edit') && (component.publicly_configurable === true))
      )
      && componentType
    ) {
      buttons.push({
        id: component.id,
        type: componentType,
        component_styles: componentStyles,
        component_btn_styles: componentBtnStyles,
      });
      if (component.components && component.components.length > 0) {
        getComponentsInAllLevels(component.components, currentViewMode, buttons, currentLvl);
      }
    }
  });

  return buttons;
};

export const generateComponentsOpsButtons = (components, unitIsRotated, onEditComponentClick, currentViewMode) => {
  let buttons = [];
  let buttonStyles = {};

  if (!components || components.length === 0) {
    return null;
  }

  buttons = getComponentsInAllLevels(components, currentViewMode, [], 0);

  if (buttons.length === 0) {
    return null;
  }

  return (
    <>{
      buttons.map((button, buttonIndex) => {

        if (unitIsRotated) {
          const originalButtonStyles = button.component_btn_styles;
          buttonStyles = generateOppositeStyles(originalButtonStyles);
        } else {
          buttonStyles = button.component_btn_styles;
        }

        return (
          <button
            key={buttonIndex}
            className={`btn btn-comp-edit btn-no-label btn-${button.type}`}
            data-component-id={button.id}
            data-component-type={button.type}
            style={buttonStyles}
            onClick={(e) => onEditComponentClick(e, button.id)}>
            <span>Modify</span>
          </button>
        );
      })
    }</>
  );
};

export const getTranslation = (string_id, language_id, default_value) => {
  let output = string_id;

  if (default_value !== null) {
    output = default_value;
  } else {
    console.log('No default value provided for string', string_id);
  }

  if (string_id in translations) {
    if (language_id in translations[string_id]) {
      output = translations[string_id][language_id];
    } else {
      console.log('No "' + language_id + '" translation found for string', string_id);
    }
  } else {
    console.log('No translations found for string', string_id);
  }

  return output;
};

export const getUnitFromClickedBtn = (event) => {
  const clickedBtn = event.target;
  return clickedBtn.closest('.cell').querySelector('.unit');
};

export const validateContentStructure = (contentToTest) => {
  const rootKeys = Object.keys(contentToTest);

  for (const rootKey of rootKeys) {
    if (!unitTypeCategories[rootKey]) {
      console.error(`Root item "${rootKey}" is not a valid unitTypeCategory.`);
      return false;
    }

    const { units, floors, rows, cols } = contentToTest[rootKey];

    if (!units || !floors || !rows || !cols) {
      console.error(`Root item "${rootKey}" is missing required properties (units, floors, rows, or cols).`);
      return false;
    }

    const unitKeys = Object.keys(units);

    for (const firstLevelKey of unitKeys) {
      if (!Number.isInteger(Number(firstLevelKey))) {
        console.error(`Key "${firstLevelKey}" inside "units" of "${rootKey}" is not an integer.`);
        return false;
      }

      const secondLevelKeys = Object.keys(units[firstLevelKey]);

      for (const secondLevelKey of secondLevelKeys) {
        if (!Number.isInteger(Number(secondLevelKey))) {
          console.error(`Key "${secondLevelKey}" inside second level of "units" of "${rootKey}" is not an integer.`);
          return false;
        }

        const thirdLevelKeys = Object.keys(units[firstLevelKey][secondLevelKey]);

        for (const thirdLevelKey of thirdLevelKeys) {
          if (!Number.isInteger(Number(thirdLevelKey))) {
            console.error(`Key "${thirdLevelKey}" inside third level of "units" of "${rootKey}" is not an integer.`);
            return false;
          }

          const fourthLevelKeys = Object.keys(units[firstLevelKey][secondLevelKey][thirdLevelKey]);

          for (const fourthLevelKey of fourthLevelKeys) {

            if (!unitTypes[rootKey] || !unitTypes[rootKey][fourthLevelKey]) {
              console.error(`Key "${fourthLevelKey}" inside fourth level of "units" of "${rootKey}" is not a valid unit type.`);
              return false;
            }
          }
        }
      }
    }
  }

  return true;
};
