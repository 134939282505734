// Floor.js

import React, { useState } from "react";
import { Trash2, RotateCw, Move } from 'react-feather';
import Unit from './Unit';
import { unitTypes } from './variables';
import { getTranslation } from './utils';

const Floor = ({
  floorIndex,
  floorTitles,
  onDrop,
  allowDrop,
  onDragStartFromCell,
  onClickDelete,
  onClickRotate,
  floorContent,
  assetsPath,
  onEditComponentClick,
  currentLanguage,
  unitCategory,
  currentViewMode,
}) => {
  const [hoveredCell, setHoveredCell] = useState({ row: null, col: null });
  const floorId = floorIndex + 1;

  const handleCellHover = (row, col) => {
    setHoveredCell({ row, col });
  };

  const handleCellLeave = (event) => {
    setHoveredCell({ row: null, col: null });
    const cell = event.target;
    const unit = cell.querySelector('.unit');

    if (unit) {
      if (unit.classList.contains("edit-mode-on")) {
        unit.classList.remove("edit-mode-on");
        unit.draggable = true;
        unit.classList.toggle('unit-draggable');
      }
    }
  };

  const handleMouseEnter = (row, col) => {
    handleCellHover(row, col);
  };

  const handleMouseLeave = (event) => {
    handleCellLeave(event);
  };

  return (
    <div className={`floor floor-${floorId} floor-${unitCategory} z-index-base`} key={floorIndex}>
      <h2>{getTranslation(floorTitles[floorIndex].translation_id, currentLanguage, floorTitles[floorIndex].translation_default)}</h2>
      <div
        className={`floor-grid`}
        data-floor-id={floorId}>
        {Array(floorContent.rows)
          .fill()
          .map((_, rowIndex) => {
            const rowId = rowIndex + 1;

            return (
              <div
                key={rowIndex}
                className={`row row-${rowId}`}
                data-floor-id={floorId}
                data-row-id={rowId}>
                {Array(floorContent.cols)
                  .fill()
                  .map((_, colIndex) => {
                    const colId = colIndex + 1;
                    const cellClasses = [
                      'cell',
                      ((currentViewMode !== 'full_view') && (hoveredCell.row === rowIndex) && (hoveredCell.col === colIndex)) ? 'cell-hovered' : '',
                    ];

                    return (
                      <div
                        key={colIndex}
                        className={`col col-${colId}`}
                        data-floor-id={floorId}
                        data-row-id={rowId}
                        data-col-id={colId}>
                        <div
                          className={cellClasses.filter(Boolean).join(' ')}
                          {...(currentViewMode !== 'full_view' && {
                            onDrop: onDrop,
                            onDragOver: allowDrop,
                            onMouseOver: () => handleMouseEnter(rowIndex, colIndex),
                            onMouseOut: (e) => handleMouseLeave(e),
                          })}
                          data-floor-id={floorId}
                          data-row-id={rowId}
                          data-col-id={colId}
                        >
                          {floorContent.units[floorId] &&
                            floorContent.units[floorId][rowId] &&
                            floorContent.units[floorId][rowId][colId] &&
                            Object.keys(floorContent.units[floorId][rowId][colId]).map((unitId) => {
                              const unitType = unitTypes[unitCategory][unitId];
                              const unitComponentSettings = floorContent.units[floorId][rowId][colId][unitId].components;
                              const unitStyles = {};
                              let unitRotation = false;

                              if ('rotation' in unitType) {
                                unitRotation = unitType.rotation;
                              }

                              if ('rotation' in floorContent.units[floorId][rowId][colId][unitId]) {
                                unitRotation = floorContent.units[floorId][rowId][colId][unitId].rotation;
                              }

                              return (
                                <Unit
                                  key={unitId}
                                  unitType={unitType}
                                  floorId={floorId}
                                  rowId={rowId}
                                  colId={colId}
                                  draggable={false}
                                  assetsPath={assetsPath}
                                  componentSettings={unitComponentSettings}
                                  onEditComponentClick={onEditComponentClick}
                                  unitStyles={unitStyles}
                                  unitCategory={unitCategory}
                                  unitIsRotated={unitRotation}
                                  currentViewMode={currentViewMode}
                                />
                              );
                            })}

                          {currentViewMode !== 'full_view' && (
                            <div className={'cell-actions'}>
                              <span
                                className={`drag-handle`}
                                onDragStart={(e) => onDragStartFromCell(e, rowIndex, colIndex)}
                                draggable={true}
                              ><Move size={16}/><span>Move</span></span>
                              <button
                                className={`btn btn-rotate btn-with-icon btn-no-label`}
                                onClick={(e) => onClickRotate(e, rowIndex, colIndex)}
                              ><RotateCw size={16}/><span>Rotate</span></button>
                              <button
                                className={`btn btn-delete btn-with-icon btn-no-label`}
                                onClick={(e) => onClickDelete(e, rowIndex, colIndex)}
                              ><Trash2 size={16}/><span>Delete</span></button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default Floor;
