// Settings.js

import React from 'react';
import { getTranslation, updateFloorContent } from './utils';
import {
  floorPlanDefaults,
  unitTypeCategories
} from './variables';

const Settings = ({
  currentLanguage,
  unitCategory,
  setUnitCategory,
  floorContent,
  setFloorContent,
  currentViewMode,
}) => {
  const maxFloorsReached= floorContent[unitCategory].floors >= floorPlanDefaults[unitCategory]['maxFloors'];
  const minFloorsReached= floorContent[unitCategory].floors <= floorPlanDefaults[unitCategory]['minFloors'];
  const maxRowsReached= floorContent[unitCategory].rows >= floorPlanDefaults[unitCategory]['maxFloorRows'];
  const minRowsReached= floorContent[unitCategory].rows <= floorPlanDefaults[unitCategory]['minFloorRows'];
  const maxColsReached= floorContent[unitCategory].cols >= floorPlanDefaults[unitCategory]['maxFloorCols'];
  const minColsReached= floorContent[unitCategory].cols <= floorPlanDefaults[unitCategory]['minFloorCols'];
  const addFloorBtnLabel = getTranslation('add_floor_btn_lbl', currentLanguage, 'Floor +');
  const removeFloorBtnLabel = getTranslation('remove_floor_btn_lbl', currentLanguage, 'Floor -');
  const addRowBtnLabel = getTranslation('add_row_btn_lbl', currentLanguage, 'Row +');
  const removeRowBtnLabel = getTranslation('remove_row_btn_lbl', currentLanguage, 'Row -');
  const addColBtnLabel = getTranslation('add_col_btn_lbl', currentLanguage, 'Col +');
  const removeColBtnLabel = getTranslation('remove_col_btn_lbl', currentLanguage, 'Col -');

  const handleAddFloor = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].floors < floorPlanDefaults[unitCategory]['maxFloors']) {
      updateFloorContent(floorContent[unitCategory].floors + 1, 'add-floor', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleRemoveFloor = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].floors > floorPlanDefaults[unitCategory]['minFloors']) {
      const floorsData = {
        deletedId: floorContent[unitCategory].floors,
        newValueForLevel: floorContent[unitCategory].floors - 1,
      };
      updateFloorContent(floorsData, 'remove-floor', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].rows < floorPlanDefaults[unitCategory]['maxFloorRows']) {
      updateFloorContent(floorContent[unitCategory].rows + 1, 'add-row', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleRemoveRow = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].rows > floorPlanDefaults[unitCategory]['minFloorRows']) {
      const rowsData = {
        deletedId: floorContent[unitCategory].rows,
        newValueForLevel: floorContent[unitCategory].rows - 1,
      };
      updateFloorContent(rowsData, 'remove-row', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleAddCol = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].cols < floorPlanDefaults[unitCategory]['maxFloorCols']) {
      updateFloorContent(floorContent[unitCategory].cols + 1, 'add-col', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleRemoveCol = (event) => {
    event.preventDefault();
    if(floorContent[unitCategory].cols > floorPlanDefaults[unitCategory]['minFloorCols']) {
      const colsData = {
        deletedId: floorContent[unitCategory].cols,
        newValueForLevel: floorContent[unitCategory].cols - 1,
      };
      updateFloorContent(colsData, 'remove-col', floorContent, setFloorContent, unitCategory);
    }
  };

  const handleSetCategory = (event, unitCategoryId) => {
    event.preventDefault();

    if (unitCategoryId in unitTypeCategories) {
      if (unitCategory !== unitCategoryId) {
        setUnitCategory(unitCategoryId);
      } else {
        console.log('Unit category already set to (' + unitCategoryId + ')');
      }
    } else {
      console.log('Unknown unit category (' + unitCategoryId + ') in unitTypeCategories', unitTypeCategories);
    }
  };

  const getAvailableUnitTypeCategories = () => {
    let availableUnitTypeCats = [];

    Object.keys(unitTypeCategories).forEach((unitCategoryId) => {
      if (
        (currentViewMode === 'basic_edit' && unitTypeCategories[unitCategoryId].publicly_configurable === true)
        || (currentViewMode === 'full_edit' && unitTypeCategories[unitCategoryId].configurable === true)
      ) {
        availableUnitTypeCats.push(unitCategoryId);
      }
    });

    if (
      availableUnitTypeCats.length === 1
      && (unitCategory === availableUnitTypeCats[0])
    ) {
      availableUnitTypeCats = [];
    }

    return availableUnitTypeCats;
  }

  return (
    <div className="settings">
      {
        (getAvailableUnitTypeCategories().length > 0)
        && (
          <div className="category-settings">
            {getAvailableUnitTypeCategories().map((unitCategoryId) => {
              const unitCategoryBtnLabel = getTranslation(unitCategoryId, currentLanguage, unitCategoryId);

              return (
                <button
                  key={unitCategoryId}
                  className={`btn set-${unitCategoryId}`}
                  onClick={(e) => handleSetCategory(e, unitCategoryId)}
                  disabled={unitCategory === unitCategoryId}
                >{unitCategoryBtnLabel}</button>
              );
            })}
          </div>
        )
      }
      <div className="floor-settings">
        <button
          className="btn add-floor"
          onClick={handleAddFloor}
          disabled={maxFloorsReached}
        >{addFloorBtnLabel}</button>
        <button
          className="btn remove-floor"
          onClick={handleRemoveFloor}
          disabled={minFloorsReached}
        >{removeFloorBtnLabel}</button>
      </div>
      <div className="row-settings">
        <button
          className="btn add-row"
          onClick={handleAddRow}
          disabled={maxRowsReached}
        >{addRowBtnLabel}</button>
        <button
          className="btn remove-row"
          onClick={handleRemoveRow}
          disabled={minRowsReached}
        >{removeRowBtnLabel}</button>
      </div>
      <div className="col-settings">
        <button
          className="btn add-coll"
          onClick={handleAddCol}
          disabled={maxColsReached}
        >{addColBtnLabel}</button>
        <button
          className="btn remove-coll"
          onClick={handleRemoveCol}
          disabled={minColsReached}
        >{removeColBtnLabel}</button>
      </div>
    </div>
  );
};

export default Settings;
