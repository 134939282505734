/* global drupalSettings */

// App.js

import './App.css';
import {
  floorPlanDefaults,
  unitTypeCategories,
  unitTypes,
  floorTitles,
  componentTypes,
  appViewModes,
} from './components/variables';
import {
  getComponentTypeProperty,
  updateFloorContent,
  getTranslation,
  getUnitFromClickedBtn,
  validateContentStructure,
} from './components/utils';
import Settings from './components/Settings';
import Topbar from './components/Topbar';
import Floor from './components/Floor';
import { useState, useEffect } from "react";

function App() {
  const initializeFloorContent = Object.keys(unitTypeCategories).reduce((accumulator, category) => {
    accumulator[category] = {
      units: {},
      floors: floorPlanDefaults[category]['defaultFloors'],
      rows: floorPlanDefaults[category]['defaultFloorRows'],
      cols: floorPlanDefaults[category]['defaultFloorCols'],
    };
    return accumulator;
  }, {});

  const [floorContent, setFloorContent] = useState(initializeFloorContent);
  const [initialFloorContentUpdated, setInitialFloorContentUpdated] = useState(false);
  const [_modulePath, setModulePath] = useState(null); // eslint-disable-line no-unused-vars
  const [drupalAppPath, setDrupalAppPath] = useState(null);
  const [language, setLanguage] = useState('nl');
  const [viewModeKeys, setViewModeKeys] = useState(Object.keys(appViewModes)); // eslint-disable-line no-unused-vars
  const defaultViewMode = viewModeKeys.find(
    key => appViewModes[key].default === true
  );
  const [viewMode, setViewMode] = useState(defaultViewMode);
  const [viewModeOverride, setViewModeOverride] = useState(false);
  const unitCategoryKeys = Object.keys(unitTypeCategories);
  const [unitCategory, setUnitCategory] = useState(unitCategoryKeys[0]);
  const [externalFieldId, setExternalFieldId] = useState(false);

  useEffect(() => {
    if (
      typeof drupalSettings !== 'undefined'
      && 'react_app' in drupalSettings
      && 'module_path' in drupalSettings.react_app
      && 'react_app_path' in drupalSettings.react_app
    ) {
      setModulePath(drupalSettings.react_app.module_path);
      setDrupalAppPath('/' + drupalSettings.react_app.module_path + drupalSettings.react_app.react_app_path + '/');

      if (
        'path' in drupalSettings
        && 'currentLanguage' in drupalSettings.path
      ) {
        setLanguage(drupalSettings.path.currentLanguage);
      }

      if (
        'view_mode' in drupalSettings.react_app
        && viewModeKeys.includes(drupalSettings.react_app.view_mode)
        && drupalSettings.react_app.view_mode !== viewMode
      ) {
        setViewMode(drupalSettings.react_app.view_mode);
      }

      if (
        'external_field_id' in drupalSettings.react_app
        && (externalFieldId === false)
      ) {
        setExternalFieldId(drupalSettings.react_app.external_field_id);
      }

      if (
        'saved_content' in drupalSettings.react_app
        && (initialFloorContentUpdated === false)
        && (drupalSettings.react_app.saved_content !== null)
        && (drupalSettings.react_app.saved_content !== '')
      ) {
        let parsedSavedContent = false;

        try {
          parsedSavedContent = JSON.parse(drupalSettings.react_app.saved_content);
        } catch (e) {
          console.error('The variable "saved_content" didn\'t contain valid json.');
        }

        if (
          parsedSavedContent !== false
          && validateContentStructure(parsedSavedContent)
        ) {
          setFloorContent(parsedSavedContent);
          setInitialFloorContentUpdated(true);
        }
      }
    }
    else {
      setModulePath('');
      setDrupalAppPath('');

      const demoContent = {
        cat_6x3: {
          units: {},
          floors: 1,
          rows: 1,
          cols: 6,
        },
        cat_8x3: {
          units: {},
          floors: 1,
          rows: 1,
          cols: 6,
        },
        cat_5x2_40: {
          units: {},
          floors: 1,
          rows: 1,
          cols: 6,
        }
      }

      // Set Demo Content as default but don't reset when overridden.
      if (
        (Object.keys(floorContent.cat_6x3.units).length === 0)
        && (Object.keys(floorContent.cat_8x3.units).length === 0)
        && (Object.keys(floorContent.cat_5x2_40.units).length === 0)
        && (initialFloorContentUpdated === false)
        && validateContentStructure(demoContent)
      ) {
        setFloorContent(demoContent);
        setInitialFloorContentUpdated(true);
      }

      if (viewModeOverride === false) {
        setViewMode('full_edit');
        //setViewMode('basic_edit');
      }
    }

    if (externalFieldId !== false) {
      const externalField = document.getElementById(externalFieldId);
      if (externalField) {
        externalField.value = JSON.stringify(floorContent);
        console.log('Updated field with ID: ' + externalFieldId);
      } else {
        console.error('Field not found by ID: ' + externalFieldId);
      }
    }

    console.log('floorContent', floorContent);
  }, [language, viewModeKeys, viewMode, floorContent, initialFloorContentUpdated, externalFieldId, viewModeOverride]);

  const assetsPath = drupalAppPath;

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const onDragStartFromTopbar = (event) => {
    event.dataTransfer.effectAllowed = "copy";
    const draggedElement = event.target;
    event.dataTransfer.setData("text/html", draggedElement.outerHTML);
  };

  const onDragStartFromCell = (event) => {
    event.dataTransfer.effectAllowed = "move";
    const draggedElement = event.target.closest('.cell').querySelector('.unit');
    draggedElement.parentNode.id = "startCell";
    event.dataTransfer.setData("text/html", draggedElement.outerHTML);
  };

  const onDrop = (event) => {
    event.preventDefault();

    const parser = new DOMParser();
    // The Unit that is currently being dragged.
    const draggedUnit = event.dataTransfer.getData("text/html");
    // The cell where the draggedUnit is being dropped into.
    const dropzone = event.target;
    // An ID which may be present on the previous cell from which the draggedUnit is dragged from.
    const startCell = document.getElementById("startCell");
    // DOM Node created from the draggedUnit HTML.
    const newUnitNode = parser.parseFromString(draggedUnit, "text/html").body.firstChild;
    // Destination Floor ID.
    const destFloorId = dropzone.dataset.floorId;
    // Destination Row ID.
    const destRowId = dropzone.dataset.rowId;
    // Destination Col ID.
    const destColId = dropzone.dataset.colId;

    // A new unit is being placed.
    if ((startCell === null) && dropzone.classList.contains("cell")) {
      newUnitNode.dataset.floorId = dropzone.dataset.floorId;
      newUnitNode.dataset.rowId = dropzone.dataset.rowId;
      newUnitNode.dataset.colId = dropzone.dataset.colId;

      updateFloorContent(newUnitNode, 'add-unit', floorContent, setFloorContent, unitCategory);
    }

    // An existing unit is being moved.
    if ((startCell !== null) && dropzone.classList.contains("cell")) {
      // The unit destination is valid, move is happening.
      if (!dropzone.classList.contains("unit")) {
        const sourceFloorId = newUnitNode.dataset.floorId;
        const sourceRowId = newUnitNode.dataset.rowId;
        const sourceColId = newUnitNode.dataset.colId;
        const sourceUnitId = newUnitNode.dataset.unitId;
        const unitData = {
          unitId: sourceUnitId,
          source: {
            floorId: sourceFloorId,
            rowId: sourceRowId,
            colId: sourceColId,
          },
          destination: {
            floorId: destFloorId,
            rowId: destRowId,
            colId: destColId,
          }
        };

        if (floorContent[unitCategory].units[sourceFloorId][sourceRowId][sourceColId][sourceUnitId]) {
          updateFloorContent(unitData, 'move-unit', floorContent, setFloorContent, unitCategory);
        }
        else {
          console.log('something went wrong while moving unit');
        }
      }

      // Startcell id not needed anymore.
      startCell.removeAttribute("id");
    }
  };

  const handleDeleteUnit = (event, rowIndex, colIndex) => {
    event.preventDefault();
    const unit = getUnitFromClickedBtn(event);
    updateFloorContent(unit, 'delete-unit', floorContent, setFloorContent, unitCategory);
  };

  const handleRotateUnit = (event, rowIndex, colIndex) => {
    event.preventDefault();
    const unit = getUnitFromClickedBtn(event);
    const unitHasRotatedClass = unit.classList.contains("unit-rotated");
    const unitData = {
      unitId: unit.dataset.unitId,
      floorId: unit.dataset.floorId,
      rowId: unit.dataset.rowId,
      colId: unit.dataset.colId,
      rotation: !unitHasRotatedClass,
    };

    updateFloorContent(unitData, 'update-rotation', floorContent, setFloorContent, unitCategory);
  };

  const handleEditComponentClick = (event, buttonId) => {
    event.preventDefault();
    const clickedBtn = event.currentTarget;
    const componentIdFromBtn = clickedBtn.dataset.componentId;
    const unitComponent = clickedBtn.closest('.unit');
    const unitId = unitComponent.dataset.unitId;
    const unitCategoryId = unitComponent.dataset.unitCat;
    const compTypeIdFromCompId = unitTypes[unitCategoryId][unitId].components.find(
      (component) => component.id === componentIdFromBtn
    )?.component_type_id;
    const componentImage = unitComponent.querySelector('.unit-components .' + componentIdFromBtn + ' > img');
    const defaultImage = getComponentTypeProperty(compTypeIdFromCompId, 'default_img', componentTypes);
    const componentImages = getComponentTypeProperty(compTypeIdFromCompId, 'component_images', componentTypes);
    const componentImagesKeys = Object.getOwnPropertyNames(componentImages);
    let componentData = {
      unit: unitComponent,
      component_id: componentIdFromBtn,
      component_default: defaultImage,
      component_value: 0,
    };

    // Get the current image index for the component
    const currentImageIndex = componentImage.dataset.componentImageId;

    // Get the next image index (looping back to 0 if it's the last one)
    const nextImageIndex = (componentImagesKeys.indexOf(currentImageIndex.toString()) + 1) % componentImagesKeys.length;

    // Update the src value of the image and the data-component-image-id attribute
    componentImage.src = assetsPath + componentImages[nextImageIndex];
    componentImage.dataset.componentImageId = nextImageIndex.toString();
    componentData.component_value = nextImageIndex;
    updateFloorContent(componentData, 'update-component', floorContent, setFloorContent, unitCategory);
  };

  const handleViewModeClick = (event, viewModeId) => {
    event.preventDefault();
    setViewModeOverride(true);
    setViewMode(viewModeId);
  };

  // Filter out "archived" unit types available in the Topbar.
  // This way old configurations keep working.
  function filterUnitTypes(unitTypes) {
    if (!unitTypes) return [];

    return Object.entries(unitTypes)
      .filter(([_, unit]) => !unit.archived)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }

  return (
    <div className={`rob-units-configurator grid-${unitCategory} vm-${viewMode}`}>
      <header className={'app-header'}>
        <div className={`view-mode-links`}>
          {viewModeKeys.map((viewModeId) => {
            return (
              <button
                key={viewModeId}
                onClick={(e) => handleViewModeClick(e, viewModeId)}
                disabled={viewModeId === viewMode}
              >
                {viewModeId}
              </button>
            );
          })}
        </div>

        {viewMode !== 'full_view' && (
          <Settings
            currentLanguage={language}
            unitCategory={unitCategory}
            setUnitCategory={setUnitCategory}
            floorContent={floorContent}
            setFloorContent={setFloorContent}
            currentViewMode={viewMode}
          />
        )}

        {viewMode !== 'full_view' && (
          <Topbar
            unitTypes={filterUnitTypes(unitTypes[unitCategory])}
            onDragStartFromTopbar={onDragStartFromTopbar}
            assetsPath={assetsPath}
            unitCategory={unitCategory}
            currentViewMode={viewMode}
          />
        )}
      </header>

      <main className={'app-body'}>
        {
          viewMode === 'full_view' && (
            <h2>{getTranslation('config_title_6x3', language, 'Configuration for 6x3 Units')}</h2>
          )
        }

        {Array(floorContent[unitCategory].floors)
          .fill()
          .map((_, floorIndex) => {
            return (
              <Floor
                key={`${floorIndex}`}
                floorIndex={floorIndex}
                floorTitles={floorTitles}
                onDrop={onDrop}
                allowDrop={allowDrop}
                onDragStartFromCell={onDragStartFromCell}
                onClickDelete={handleDeleteUnit}
                onClickRotate={handleRotateUnit}
                floorContent={floorContent[unitCategory]}
                assetsPath={assetsPath}
                onEditComponentClick={handleEditComponentClick}
                currentLanguage={language}
                unitCategory={unitCategory}
                currentViewMode={viewMode}
              />
            );
          })
        }

        {
          viewMode === 'full_view'
          && Object.keys(floorContent['cat_8x3'].units).length > 0 && (
            <h2>{getTranslation('config_title_8x3', language, 'Configuration for 8x3 Units')}</h2>
          )
        }

        {
          viewMode === 'full_view'
          && Object.keys(floorContent['cat_8x3'].units).length > 0
          && Array(floorContent['cat_8x3'].floors)
            .fill()
            .map((_, floorIndex) => {
              return (
                <Floor
                  key={`view_${floorIndex}`}
                  floorIndex={floorIndex}
                  floorTitles={floorTitles}
                  onDrop={onDrop}
                  allowDrop={allowDrop}
                  onDragStartFromCell={onDragStartFromCell}
                  onClickDelete={handleDeleteUnit}
                  onClickRotate={handleRotateUnit}
                  floorContent={floorContent['cat_8x3']}
                  assetsPath={assetsPath}
                  onEditComponentClick={handleEditComponentClick}
                  currentLanguage={language}
                  unitCategory={'cat_8x3'}
                  currentViewMode={viewMode}
                />
              );
            }
          )
        }

        {
          viewMode === 'full_view'
          && Object.keys(floorContent['cat_5x2_40'].units).length > 0 && (
            <h2>{getTranslation('config_title_5x2_40', language, 'Configuration for 5x2.40 Units')}</h2>
          )
        }

        {
          viewMode === 'full_view'
          && Object.keys(floorContent['cat_5x2_40'].units).length > 0
          && Array(floorContent['cat_5x2_40'].floors)
            .fill()
            .map((_, floorIndex) => {
                return (
                  <Floor
                    key={`view_${floorIndex}`}
                    floorIndex={floorIndex}
                    floorTitles={floorTitles}
                    onDrop={onDrop}
                    allowDrop={allowDrop}
                    onDragStartFromCell={onDragStartFromCell}
                    onClickDelete={handleDeleteUnit}
                    onClickRotate={handleRotateUnit}
                    floorContent={floorContent['cat_5x2_40']}
                    assetsPath={assetsPath}
                    onEditComponentClick={handleEditComponentClick}
                    currentLanguage={language}
                    unitCategory={'cat_5x2_40'}
                    currentViewMode={viewMode}
                  />
                );
              }
            )
        }

        {
          viewMode === 'full_view' && (
            <div className={`legend`}>
              <h4>{getTranslation('legend_title', language, 'Legend')}</h4>
              <ul>
                <li><span className={`legend-icon`}><img src={`${assetsPath}components/window_bars/bars-1.svg`} alt={`icon`} /></span> {getTranslation('legend_win_bars', language, 'Window bars')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}components/airco/airco-1.svg`} alt={`icon`} /></span> {getTranslation('legend_airco', language, 'Airco')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}components/airco/airco-2.svg`} alt={`icon`} /></span> {getTranslation('legend_heat', language, 'Heat pump')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}icons/icon_boiler.svg`} alt={`icon`} /></span> {getTranslation('legend_boiler', language, 'Boiler')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}icons/icon_elektriciteitskast.svg`} alt={`icon`} /></span> {getTranslation('legend_electrical_cabinet', language, 'Electrical cabinet')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}icons/icon_lamp.svg`} alt={`icon`} /></span> {getTranslation('legend_light', language, 'Light bulb')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}icons/icon_stopcontact.svg`} alt={`icon`} /></span> {getTranslation('legend_power_outlet', language, 'Power outlet')}</li>
                <li><span className={`legend-icon`}><img src={`${assetsPath}icons/icon_verwarming.svg`} alt={`icon`} /></span> {getTranslation('legend_heating', language, 'Heating')}</li>
              </ul>
            </div>
          )
        }
      </main>
    </div>
  );
}
export default App;
